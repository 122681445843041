<template>
    
    <Toast position="bottom-right" />

    <div class="up_monitor_content_inner">
        <div class="up_main_container">
            <div class="up_page_header">
                <h2 class="up_page_title p-mr-6">Monitoring</h2>
                <form>
                    <div class="p-d-flex p-align-left">
                        <div class="up_header_field">
                            <Dropdown v-model="orderSearch.websiteId" :options="allWebsiteData.data" id="u_sitename"
                                optionLabel="siteUrl" optionValue="siteUrl" placeholder="Select Website"
                                @change="fetchMonitoring(orderSearch.websiteId)" />
                        </div>
                    </div>
                </form>
            </div>
            <div class="bottom_grid_wrapper"  v-if="orderSearch.websiteId">
                <div class="classic_grid">
                    <div class="monitor_grid_wrapper">
                        <div class="loader_monitoring" v-if="monitorData.loading">
                        <div class="half-circle-spinner"> 
                            <div class="circle circle-1"></div>
                            <div class="circle circle-2"></div>
                        </div>
                        </div>
                        <ul>
                            <li v-for="(val, i) in monitorData.data" :key="i">
                                <!-- <div class="others_conditions">
                                    <div class="monitor_en_dis">
                                        <InputSwitch v-model="checked20" v-tooltip="'Enable Email Notification'" />
                                    </div>
                                   <button id="rescan-ssl-certificate-validity" class="run-test do-rescan-btn monitor_rescan"
                                            data-servicelogid="99" data-websiteid="219" data-serviceid="1"
                                            data-toggle="tooltip" data-placement="left" title="" style="display: flex"
                                            data-original-title="Rescan" @click="confimationModal(val)" v-tooltip.left="'Reload data'">
                                            <img src="https://console.staging.truabilities.com/frontend_assets/dist/img/replay-blue.png"
                                                alt="Rescan image">
                                    </button>
                                </div> -->
                              
                                <div :id="'monitor_box' + i"  @click="openBox(val, i)" class="monitor_inner_box" :class="seletedItem._id === 'monitor_box'+i && seletedItem.clicked === true ? 'selected': ''" > <!---  'selected'  use this class when you select this service-->
                                    
                                  <!-- <div  :class="monitorData.selectClass === true ? 'monitor_box selected' : 'monitor_box'"> -->
                                   <div  class="monitor_box">
                                    <figure>
                                                <!-- <img :src="val.serviceName === 'SSL Certificate Validity' ? imageUrlFrontEnd + '/dist/img/SSL.svg' :
                                        val.serviceName === 'Sucuri Site Check' ? imageUrlFrontEnd + 'dist/img/sucuri-site-check.svg' : val.serviceName === 'Domain Blacklist' ?
                                            imageUrlFrontEnd + 'dist/img/domain-blacklisting.svg' : val.serviceName === 'Google Safe Browsing' ? imageUrlFrontEnd + 'dist/img/google-safe-browsing.svg' :
                                                val.serviceName === 'Page Speed' ? imageUrlFrontEnd + 'dist/img/Uptime.svg' : imageUrlFrontEnd + 'dist/img/yandex-safe-browsing.svg'"
                                                    alt=""> -->
                                                    <img :src="val.serviceName === 'SSL Certificate Validity' ? 'https://truabilities-prod.s3.us-west-1.amazonaws.com/console/SSL.png?AWSAccessKeyId=AKIAZJ4VKLIY3CG2OX4B&Expires=1978806542&Signature=TsyaIvLDF%2BdzcOHDHFaOCiS9eVo%3D' :
                                        val.serviceName === 'Sucuri Site Check' ? 'https://truabilities-prod.s3.us-west-1.amazonaws.com/console/sucuri-site-check.png?AWSAccessKeyId=AKIAZJ4VKLIY3CG2OX4B&Expires=1978806542&Signature=cUJXgLwyaeINCmaozRLnNJXlAQk%3D' : val.serviceName === 'Domain Blacklist' ?
                                            'https://truabilities-prod.s3.us-west-1.amazonaws.com/console/domain-blacklisting.png?AWSAccessKeyId=AKIAZJ4VKLIY3CG2OX4B&Expires=1978806542&Signature=8hidTZGN6gA9SL14najz%2FxDKnEI%3D' : val.serviceName === 'Google Safe Browsing' ? 'https://truabilities-prod.s3.us-west-1.amazonaws.com/console/google-safe-browsing.png?AWSAccessKeyId=AKIAZJ4VKLIY3CG2OX4B&Expires=1978806542&Signature=8gz8hzbbXhqekqQbUaDAERsKqow%3D' :
                                                val.serviceName === 'Page Speed' ? 'https://truabilities-prod.s3.us-west-1.amazonaws.com/console/Uptime.png?AWSAccessKeyId=AKIAZJ4VKLIY3CG2OX4B&Expires=1978806542&Signature=NjewuDcX7keu7QZ9kaLAhgm5avw%3D' : 'https://truabilities-prod.s3.us-west-1.amazonaws.com/console/yandex-safe-browsing.png?AWSAccessKeyId=AKIAZJ4VKLIY3CG2OX4B&Expires=1978806542&Signature=Ujyc4ZXQTn%2FgR0k8pd10TJt4I48%3D'"
                                                    alt="">
                                            </figure>
                                        
                                    </div>
                                    <h4>{{ val.serviceName }}</h4>
                                    <p>{{ dateDiff(formatDate(val.createdAt), new Date()) }} </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="classic_grid"  v-if="monitorData.isShow">
                    <div class="up_moniter_preview_card">
                        <span class="p-dialog-title monitor_branding_title">
                            <h3>{{ monitorData.serviceName }}<span class="status"><i
                                        class="fa fa-check-circle"></i>{{ monitorData.isQualified === true ?
                                                'Pass' : 'Fail'
                                        }}</span></h3>
                        </span>
                        <div class="monitor_Branding_info">
                            <div :class="monitorData.serviceName === 'Domain Blacklist' ? 'sup_Branding_info_section domain_blocklist' : 'sup_Branding_info_section' ">
                                <!-- <div class="sup_section_title">
                                    <h4>&nbsp;</h4>
                                </div> -->
                                <ul>
                                    <li class="align-item"
                                        v-for='(value, index) in monitorData.serviceData' :key='index'>
                                        <div :class="monitorData.serviceName === 'Domain Blacklist' ?  'domain_blocklist_wrapper'  : monitorData.serviceName === 'SSL Certificate Validity' ? 'other_block_list': '' ">
                                        <span  v-if="monitorData.serviceName === 'Domain Blacklist'">
                                        <i  :class="value === 'clean' ? 'pi pi-check-circle greenTick' : value === 'unrated' ? 'pi pi-exclamation-triangle unratedSign' : 'pi pi-exclamation-circle suspiciousCircel'"></i></span>
                                            <strong>{{ monitorData.serviceName === 'Domain Blacklist' ? index : camelToWords(index) }}</strong>
                                            <span v-if="monitorData.serviceName !== 'Domain Blacklist'">
                                            : {{ value }}
                                            </span>
                                        </div>
                                        
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <Dialog header="Confirmation" v-model:visible="confirmationModal.modalState" :style="{ width: '350px' }"
        :modal="true">
        <div class="confirmation-content p-d-flex p-items-center">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 1.6rem" />
            <span>Do you want to proceed?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" @click="confirmationModal.modalState = false" class="p-button-text"
                autofocus />
            <Button label="Yes" icon="pi pi-check"
                @click="reMonitoring(confirmationModal.modaldata.data), confirmationModal.modalState = false"
                class="p-button-primary" />
        </template>
    </Dialog>

</template>

<script>
import { userPortalWebsite, websiteMonitoring, websiteMonitoringStatus, websiteReMonitoringReport } from '../../../../config/appUrls'
import { imageUrlFrontEnd, apiKey } from '../../../../config/constant';
import { useStore } from 'vuex';
import { usFormatDate, camelToWords, formatDate } from "../../../../helper/utility/utility";
import axios from "axios";
import { useToast } from "primevue/usetoast";
// import ScrollPanel from 'primevue/scrollpanel';
import { onBeforeMount, ref, reactive } from 'vue';

export default {
    name: 'Monitoring',
    components: {
    },
    setup() {
        //Vuex store data call fucntion
        const store = useStore();

        //Toast Message call
        const toast = useToast();       
        const seletedItem = reactive({
            clicked : false,
            _id : ''
        })
        const checked1 = ref([
            { name: "true", code: 1 },
            { name: "false", code: 0 }
        ]);

        const allWebsiteData = reactive({
            data: '',
            status: ''
        });

        const orderSearch = reactive({
            websiteId: null
        });

        const monitorData = reactive({
            data: null,
            status: null,
            loading: false,
            isShow: false,
            serviceName: null,
            isQualified: true,
            serviceData: null,
            isActive: false
        });

        const confirmationModal = reactive({
            modalState: false,
            modaldata: ''
        })

        const allSslDetails = reactive({
            serialNumber: null,
            subjectAltName: null,
            fingerprint: null,
            validFrom: null,
            validTo: null,
            issuer: null,
            subject: null
        })
        const allYandexDetails = reactive({
            method: null,
            result: null,
            category: null,
            engine_name: null,
            engineName: null
        })

        onBeforeMount(() => {
            fetchWebsiteList();
        });

        //Website list for Website search dropdown
        const fetchWebsiteList = async () => {
            const response = await axios.get( userPortalWebsite, {
                params:{
                    status: 'all'
                },
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                }, 
            });
            allWebsiteData.data = response.data.data.website.filter(x=>x.deletedAt === null);
        }

        const fetchMonitoring = async (websiteUrl) => {
            monitorData.serviceData = null;
            allSslDetails.serialNumber = null;
            allSslDetails.subjectAltName = null;
            allSslDetails.fingerprint = null;
            allSslDetails.validFrom = null;
            allSslDetails.validTo = null;
            allSslDetails.issuer = null;
            allSslDetails.subject = null;
            monitorData.isShow = false;
            monitorData.loading = true;
            try {
                const response = await axios.post(websiteMonitoring, {
                siteUrl: websiteUrl
            }, {
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });

            if (response.data.status === 200) {
                monitorData.data = response.data.data;
                monitorData.status = response.data.status;
                monitorData.loading = false;
                let _sslDt;
                for (let i = 0; i < monitorData.data.length; i++) {
                    const element = monitorData.data[i].serviceName;
                    if(element === 'SSL Certificate Validity'){
                        _sslDt = monitorData.data[i];
                    }else{
                        break;
                    }
                }
                openBox(_sslDt,0);
            }
            } catch(err) {                
                console.log('Error', err);
                toast.add({severity:'error', summary:'Something went wrong', detail:'Looks like there is something wrong with this url', life: 5000});
                monitorData.loading = false;
            }
        }
        let simplevar;
        const openBox = async (val,i) => {
            monitorData.isShow = true;
            monitorData.isActive = true;
            monitorData.serviceName = val.serviceName;
            monitorData.isQualified = val.isQualified;
            seletedItem._id = 'monitor_box'+i;
            seletedItem.clicked = true;
            simplevar = val.serviceData;
            if (monitorData.serviceName === 'SSL Certificate Validity') {
                let serviceArr = {};
                for (const key in simplevar) {
                    if (Object.hasOwnProperty.call(simplevar, key)) {
                        const element = simplevar[key];
                        if(key === 'expiresIn'){
                            serviceArr[key] = element+' Days';
                        }else if(key === 'validTo'){
                            serviceArr[key] = usFormatDate(element);
                        }else if(key === 'validFrom'){
                            serviceArr[key] = usFormatDate(element);
                        }else if(key === 'subjectAltName'){
                            serviceArr[key] = element.replace(/,/g, " ");
                        }else{
                            serviceArr[key] = element;
                        }
                        
                    }
                }
                delete serviceArr.issuer;
                delete serviceArr.status;
                delete serviceArr.pemEncoded;
                delete serviceArr.subject;

                monitorData.serviceData = serviceArr;
            }
            else if (monitorData.serviceName === 'Yandex Safe Browsing') {
                let serviceArr = {};
                if (simplevar.result === "clean") {
                    serviceArr['Your site analysis by Yandex safe Browsing is '] = 'Secure'
                } else if (simplevar.result === 'unrated') {
                    serviceArr['Your site analysis by Yandex safe Browsing is '] = 'Unrated'
                } else {
                    serviceArr['Your site analysis by Yandex safe Browsing is '] = 'Harmful'
                }
                monitorData.serviceData = serviceArr;
            }
            else if (monitorData.serviceName === 'Sucuri Site Check') {
                let serviceArr = {};
                if (simplevar.result === "clean") {
                    serviceArr['Your site analysis by Sucuri Site Check is '] = 'Secure'
                } else if (simplevar.result === 'unrated') {
                    serviceArr['Your site analysis by Sucuri Site Check is '] = 'Unrated'
                } else {
                    serviceArr['Your site analysis by Sucuri Site Check is '] = 'Harmful'
                }
                monitorData.serviceData = serviceArr;
            }
            else if (monitorData.serviceName === 'Domain Blacklist') {
                let serviceArr = {};
                for (const key in simplevar) {
                    if (Object.hasOwnProperty.call(simplevar, key)) {
                        const element = simplevar[key];
                        serviceArr[key] = element.result;
                    }
                }

                monitorData.serviceData = serviceArr;
            }
            else if (monitorData.serviceName === 'Google Safe Browsing') {
                let serviceArr = {};
                if (Object.values(simplevar) === true) {
                    serviceArr['Your site ' + Object.keys(simplevar) + ' analysis by Google Safe Browsing is '] = 'Unsafe'
                } else {
                    serviceArr['Your site analysis by Google Safe Browsing is '] = 'Safe'
                }
                monitorData.serviceData = serviceArr;
            } else {
                let serviceArr = {};
                serviceArr['Your site Page Speed Score is '] = simplevar.score * 100;
                monitorData.serviceData = serviceArr;
            }

        }

        function dateDiff(startingDate, endingDate) {
            var startDate = new Date(new Date(startingDate).toISOString().substr(0, 10));
            if (!endingDate) {
                endingDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
            }
            var endDate = new Date(endingDate);
            if (startDate > endDate) {
                var swap = startDate;
                startDate = endDate;
                endDate = swap;
            }
            var startYear = startDate.getFullYear();
            var february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
            var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

            var yearDiff = endDate.getFullYear() - startYear;
            var monthDiff = endDate.getMonth() - startDate.getMonth();
            if (monthDiff < 0) {
                yearDiff--;
                monthDiff += 12;
            }
            var dayDiff = endDate.getDate() - startDate.getDate();
            if (dayDiff < 0) {
                if (monthDiff > 0) {
                    monthDiff--;
                } else {
                    yearDiff--;
                    monthDiff = 11;
                }
                dayDiff += daysInMonth[startDate.getMonth()];
            }
            if (yearDiff === 0 && monthDiff > 0) {
                return monthDiff + (monthDiff > 1 ? ' Months ' : ' Month ') + dayDiff + (dayDiff > 1 ? ' Days ago ' : ' Day ago ');
            } else if (yearDiff === 0 && monthDiff === 0) {
                return dayDiff + (dayDiff > 1 ? ' Days ago' : ' Day ago ');
            } else {
                return yearDiff + (yearDiff > 1 ? ' Years ' : ' Year ') + monthDiff + (monthDiff > 1 ? ' Months ' : ' Month ') + dayDiff + (dayDiff > 1 ? ' Days ago ' : ' Day ago ');
            }
        }

        const switchMonitorStatus = async (url, name, status) => {

            //API call start
            try {
                const options = {
                    method: 'put',
                    url: websiteMonitoringStatus,
                    params: {
                        siteUrl: url,
                        serviceName: name
                    },
                    data: JSON.stringify({
                        isEnabled: status === false ? true : false,
                    }),
                    headers: { 'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken },
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                let response = await axios(options);
                console.log(response);
            } catch (err) {
                console.log('err', err);
            }
        }

        const reMonitoring = async (sloteData) => {
            try {
                const options = {
                    method: 'put',
                    url: websiteReMonitoringReport,
                    params: {},
                    data: JSON.stringify({
                        siteUrl: sloteData.siteUrl,
                        serviceName: sloteData.serviceName
                    }),
                    headers: { 'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken },
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                const response = await axios(options);
                if (response.status === 200) {
                    toast.add({severity:'success', summary:'Re Monitoring Successfull', detail:'Website re-monitoring report generated!', life: 2000});
                }
            } catch (err) {
                console.log(err);
            }
        }

        const confimationModal = (sloteData) => {
            confirmationModal.modalState = true;
            confirmationModal.modaldata = {
                data: sloteData
            }
        }

        return {
            imageUrlFrontEnd,
            checked1,
            allWebsiteData,
            orderSearch,
            fetchMonitoring,
            monitorData,
            formatDate,
            usFormatDate,
            openBox,
            allSslDetails,
            allYandexDetails,
            dateDiff,
            switchMonitorStatus,
            confirmationModal,
            confimationModal,
            reMonitoring,
            checked20: true,
            camelToWords,
            seletedItem
        }
    }
}
</script>

<!-- <style lang="scss">


@import '../../../../assets/style/component/userportal/pages/Dashboard';
// @import '../../../../assets/style/component/userportal/pages/ManageExistingSite/ManageExistingSite';
</style> -->

<style lang="scss" scoped>
@import '../../../../assets/style/component/userportal/pages/Monitor/monitor.css';
</style>